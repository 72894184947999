// ContactPage.js
import React from 'react';
import ContactForm from './ContactForm';
import './ContactPage.css'; // Import the CSS file for styling

const ContactPage = () => {
  return (
    <div className="contact-page" id="contact">
      <h1>Contact Me</h1>
      <p>Feel free to reach out to me using the form below:</p>
      <ContactForm />
      <div className="contact-info">
        <p>Email: malanjennylee992@gmail.com</p>
        <p>Phone: +27 82 411 5344</p>
      </div>
    </div>
  );
};

export default ContactPage;
