// src/components/Home.js
import React from 'react';

const Home = () => (
  <div id="home">
    <section className="container mt-5">
      <h2 className="text-center mb-4">Welcome to my Model Portfolio</h2>
      <p className="text-center">I am an aspiring model based in Polokwane, South Africa.</p>
      <p className="text-center">Height: 5'2" (157 cm)<br></br>Weight: 53 kg<br></br>Bust: 82 cm<br></br>Waist: 66 cm<br></br>Hips: 79 cm<br></br>Eyes: Green<br></br>Hair: Ash Blond</p>
    </section>
  </div>
);

export default Home;
