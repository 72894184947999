// src/App.js
import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Header from './components/Header';
import Home from './components/Home';
import Portfolio from './components/Portfolio';
import Contact from './components/Contact';
import './global.css';

const appStyle = {
  backgroundColor: '#f2f2f2',
  minHeight: '100vh',
  display: 'flex',
  flexDirection: 'column',
};

const mainStyle = {
  flex: 1,
  overflowY: 'auto',
  padding: '20px',
};

const App = () => (
  <Router>
    <div style={appStyle} className="app">
      <Header />
      <main style={mainStyle}>
        <Routes>
          <Route
            path="/"
            element={
              <div>
                <Home />
                <Portfolio />
                <Contact />
              </div>
            }
          />
        </Routes>
      </main>
    </div>
  </Router>
);

export default App;
