// src/components/Header.js
import React from 'react';

const headerStyle = {
  backgroundColor: '#fdf5e6',
  position: 'sticky',
  top: 0,
  zIndex: 1000, // Set a higher z-index to make sure it stays above other elements
};

const Header = () => (
  <header style={headerStyle} className="text-dark text-center py-4">
    <h1 className="text-uppercase">Jenny-Lee's Portfolio</h1>
    <nav>
      <ul className="nav justify-content-center">
        <li className="nav-item">
          <a href="#home" className="nav-link text-dark">Home</a>
        </li>
        <li className="nav-item">
          <a href="#portfolio" className="nav-link text-dark">Portfolio</a>
        </li>
        <li className="nav-item">
          <a href="#contact" className="nav-link text-dark">Contact</a>
        </li>
      </ul>
    </nav>
  </header>
);

export default Header;
