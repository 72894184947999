// src/components/PortfolioItem.js
import React, { useState } from 'react';
import { Modal, Button } from 'react-bootstrap';

const PortfolioItem = ({ title, image, description }) => {
  const [showModal, setShowModal] = useState(false);

  const handleShow = () => setShowModal(true);
  const handleClose = () => setShowModal(false);

  return (
    <div className="col-md-4 mb-4">
      <div className="card">
        <img
          src={image}
          className="card-img-top"
          alt={title}
          style={{ cursor: 'pointer' }}
          onClick={handleShow}
        />
      </div>

      <Modal show={showModal} onHide={handleClose}>
        <Modal.Body>
          <img src={image} alt={title} style={{ width: '100%' }} />
          <p>{description}</p>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default PortfolioItem;
