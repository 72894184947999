// src/components/Portfolio.js
import React from 'react';
import PortfolioItem from './PortfolioItem';

const Portfolio = () => {
  const imageArray = Array.from({ length: 22 }, (_, index) => `${index + 1}.jpg`);

  console.log('Image Array:', imageArray);

  return (
    <div id="portfolio">
      <section className="container mt-5">
        <h2 className="text-center mb-4">Portfolio</h2>
        <div className="row">
          {imageArray.map((image, index) => (
            <PortfolioItem
              key={index}
              title={`Photo ${index + 1}`}
              image={`./images/${image}`}
            />
          ))}
        </div>
      </section>
    </div>
  );
};

export default Portfolio;
